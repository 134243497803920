module.exports = {
  defaultTitle: 'Amala Mary Paul',
  logo: 'https://pixabay.com/get/57e3d0404250a914f1dc8460da2932771337dfe4505572_640.png',
  author: 'Amala Mary Paul',
  url: 'https://amalamarypaul.in',
  legalName: 'Amala Mary Paul',
  defaultDescription: 'I’m Amala and I’m a full-stack webapp developer!',
  socialLinks: {
    twitter: 'http://www.twitter.com/amalampaul',
    github: 'https://github.com/amalamarypaul',
    linkedin: 'https://www.linkedin.com/in/amala-mary-paul',
    instagram: 'https://instagram.com/amalamarypaul',
  },
  googleAnalyticsID: '',
  themeColor: '#6b63ff',
  backgroundColor: '#6b63ff',
  social: {
    facebook: 'amala.mp.1',
    twitter: '@amalampaul',
  },
  address: {
    city: 'Mananthavady',
    region: 'Kerala',
    country: 'India',
    zipCode: '670644',
  },
  contact: {
    email: 'amalamarypaul@gmail.com',
    phone: '9400770894',
  },
  foundingDate: '2020'
};
